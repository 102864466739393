import { jwtDecode } from "jwt-decode";
import type { JWTDecoded, Options } from "../_model";
import { error, log } from "../const";
import type { AxiosResponse } from "axios";
export function decodeJWT(token_response: AxiosResponse, options: Options) {
  if (token_response === undefined || token_response === null) {
    return undefined;
  }
  const token = options?.auth?.events?.getToken?.({ res: token_response });
  if (token === undefined || token === null) {
    return undefined;
  } else if (typeof token !== "string") {
    error("token must be a string, i got :: token_response :: ", token_response, " :: token :: ", token);
    return undefined;
  }
  const decoded = jwtDecode(token);
  return getJWT(decoded, options, token_response);
}
export function getJWT(decoded: JWTDecoded, options: Options, res: AxiosResponse) {
  const result = decoded;

  options?.auth?.triggers?.onTokenDecoded({ jwt: result, res });
  return result;
}
